import * as React from 'react'
import Layout from "../components/Layout"
import styled from 'styled-components'
import { SEO } from "../components/Seo"

import {GiCycling} from 'react-icons/gi'
import {GiMountainCave} from 'react-icons/gi'
import {GiSandsOfTime} from 'react-icons/gi'

import { StaticImage } from 'gatsby-plugin-image'

const All = styled.section`
    display: block;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 2% 0px 2%;;


    h1 {
        font-weight: 100;
        text-align: center;
        margin: auto;
        /* text-transform: uppercase; */

        span{
            font-weight: 500;
            color: #B68A65;
        }
    }
    
    h3{
        font-weight: 300;
        text-align: center;
        margin-top: 10%;
        margin: auto;
        max-width: 80vw;
    }
    p{
        text-align: center;
    }
`

const Track = styled.article`
    display: flex;
    justify-content: center;
    /* padding: 5% 10%; */
    width: 100%;
    flex-direction: column;

    .frame{
        padding: 20px 0;
        width: 1px; 
        min-width: 100%; 
        min-height: 400px; 
        border: none; 
        ${({theme})=> theme.media.tablet}{
            height: 700px;
        }
    }

    h1{
        text-transform: uppercase;
        text-decoration: underline;
        font-family: "Righteous", cursive;
        font-size: 2.5em;
        padding-bottom: 20px;
        text-align: center;
    }

    .all{
        display: flex;
        justify-content: space-around;

        .ico{
            text-align: center;
        }

        .icon{
            width: 64px;
            height: 64px;
        }
    }

    .img{
        margin: auto;
        padding-top: 20px;

    }
`

// const Art = styled.article`
//     text-align: center;
// `

const Trasa = () => {
    return(
        <Layout>
            <All>
                <h1>Wstępna Trasa <span>Ultra Race Dolina Bugu 2024*</span></h1><br/>
                <h3>Zapomnij o miejskim zgiełku i zanurz się w piękno Doliny Bugu - odkryjesz uroki dzikiej natury, bogactwo flory i fauny oraz malownicze krajobrazy.</h3><br></br>
                {/* <p>Trasa maratonu wiedzie malowniczymi drogami. Pozostawi Ci fascynujące wrażenia i wspomnienia. A spotkane po drodze osoby mogą zaowocować przyjaźnią na całe życie.
                </p> */}
                <Track>
                <br/>
                <h1>Gigante</h1>
                    <div className='all'>
                        <div className='ico'>
                            <GiCycling className='icon'/>
                            <p>550km<br></br>przygody</p>         
                        </div>
                        <div className='ico'>
                            <GiMountainCave className='icon'/>
                            <p>2600m<br></br>wspinaczki</p>         
                        </div>
                        <div className='ico'>
                            <GiSandsOfTime className='icon'/>
                            <p>60h<br></br>limitu czasu</p>         
                        </div>
                    </div>
                    <div className='img'>
                        <StaticImage
                            src="../images/gigante2.png"
                            alt="more ultra race"
                            placeholder="blurred"
                            layout='constrained'
                            //   width={600}
                            height={600}     
                            transformOptions={{ fit: "cover", cropFocus: "attention" }}
                            className='img'
                            
                            ></StaticImage>
                    </div>
                    {/* <iframe className='frame' src="https://ridewithgps.com/embeds?type=route&id=41103043&metricUnits=true" scrolling="no" title='Roztocze650'></iframe> */}
                </Track>
                <Track>
                <br/>
                    <h1>Classico</h1>
                    <div className='all'>
                        <div className='ico'>
                            <GiCycling className='icon'/>
                            <p>290km<br></br>przygody</p>         
                        </div>
                        <div className='ico'>
                            <GiMountainCave className='icon'/>
                            <p>1500m<br></br>wspinaczki</p>         
                        </div>
                        <div className='ico'>
                            <GiSandsOfTime className='icon'/>
                            <p>36h<br></br>limitu czasu</p>         
                        </div>
                    </div>
                    <div className='img'>
                        <StaticImage
                            src="../images/classico.png"
                            alt="more ultra race"
                            placeholder="blurred"
                            layout='constrained'
                            //   width={600}
                            height={600}     
                            transformOptions={{ fit: "cover", cropFocus: "attention" }}
                            className='img'
                            
                            ></StaticImage>
                    </div>
                </Track>

                <br></br><br></br>
                <p>*Ostateczna wersja trasy zostanie przesłana uczestnikom 7 dni przed startem.</p>
            </All>
        </Layout>
    )
}

export default Trasa

export const Head = () => (
  <SEO title="Trasa | Ultramaraton Rowerowy" description="Ultra Race Dolina Bugu to zawody gravelowe wdłuz jednej z ostanich dzikich rzek w Europie. Dwie trasy 290 i 550km."/>
)